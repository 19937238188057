div.spinner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 3em;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 2000;
}

div.spinner i.fas {
  color: #007bff;
}

.spinner-wrapper {
  min-height: 10em;
}

.camper-form {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

.contact-information-form {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

#paypal-button {
  border: none;
  border-radius: 4px;
  background-color: #f7bd54;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
  cursor: pointer;
}

#paypal-button > img {
  width: 120px;
  margin: 15px 50px 10px;
}

#paypal-button:hover {
  background-color: #c59743;
  -webkit-transition: background-color 0.15;
  transition: background-color 0.15;
}

.sort-indicator {
  pointer-events: none;
}

.search-table {
  font-size: 75%;
}

.expand-icon {
  opacity: 0.5;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.expand-icon:hover {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.xy-chart {
  font-size: 10px;
}
.boolean-indicator {
  display: inline-block;
  margin: 0 0.25em;
}

html,
body,
#root {
  height: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.table th,
.table td {
  white-space: nowrap;
}

.btn.btn-link {
  text-decoration: none;
}

.admin-table td {
  font-size: 12px;
}

.admin-table tbody button {
  height: 15px;
  font-size: 10px;
  padding: 1px 10px 3px;
  line-height: 1;
}

