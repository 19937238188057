.expand-icon {
  opacity: 0.5;
  transition: opacity 300ms;
}

.expand-icon:hover {
  opacity: 1;
  transition: opacity 300ms;
}

.xy-chart {
  font-size: 10px;
}