div.spinner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 3em;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 2000;
}

div.spinner i.fas {
  color: #007bff;
}

.spinner-wrapper {
  min-height: 10em;
}
