html,
body,
#root {
  height: 100%;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.table th,
.table td {
  white-space: nowrap;
}

.btn.btn-link {
  text-decoration: none;
}

.admin-table td {
  font-size: 12px;
}

.admin-table tbody button {
  height: 15px;
  font-size: 10px;
  padding: 1px 10px 3px;
  line-height: 1;
}
